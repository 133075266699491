import { useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import SidebarAccordions from '../../menu/SidebarAccordions';

function SecondaryDrawer({
  selectedTab,
  sidebarWidth,
  parentSelected,
  toggleDrawer,
  handleSubItemClick,
  open,
}) {
  const theme = useTheme();

  const user = useSelector((state) => state.user);
  const siteId = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteId);

  const { pathname } = useLocation();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [filteredSidebarAccordions, setFilteredSidebarAccordions] = useState(SidebarAccordions);

  useEffect(() => {
    if (user?.userProfile?.user_right !== 'Admin' && user?.userProfile?.user_right !== 'Developer') {
      const updatedAccordions = Object.keys(SidebarAccordions).reduce((acc, key) => ({
        ...acc,
        [key]: SidebarAccordions[key].filter(
          (item) => item.name !== 'Indexing' && item.name !== 'Users',
        ),
      }), {});
      setFilteredSidebarAccordions(updatedAccordions);
    } else {
      setFilteredSidebarAccordions(SidebarAccordions);
    }
  }, [siteId, user]);

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="temporary"
      onClose={toggleDrawer(false)}
      elevation={4}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: '#687580', // tailwind neutral 700
          color: 'white',
          marginLeft: matches ? `${sidebarWidth}px` : 0,
          padding: '4rem 1.5rem',
          width: 240,
        },
      }}
      style={{ zIndex: '1000' }} // high number to avoid issues but lower than main drawers
    >
      <Typography variant="h6">{parentSelected}</Typography>
      <List>
        {filteredSidebarAccordions[selectedTab]?.map((obj) => (
          <Link key={obj.name} to={{ pathname: obj.link }}>
            <ListItemButton
              selected={pathname === obj.link}
              onClick={() => handleSubItemClick(obj)}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <ListItemText disableTypography primary={obj.name} className="text-sm" />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </Drawer>
  );
}

export default SecondaryDrawer;
