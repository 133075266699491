import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';

import { fetchFascias } from '../redux/fascias/Fascias';
import { fetchPIMSites } from '../redux/sites/sites';
import Header from './header/Header';
import SideBar from './sidebar/Sidebar';

const SIDEBAR_WIDTH = 100;

function Layout() {
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    } else {
      setMobileOpen(true);
    }
  };

  React.useEffect(() => {
    dispatch(fetchFascias());
    dispatch(fetchPIMSites());
  }, []);

  return (
    <Box className="flex">
      <Header handleLeftDrawerToggle={handleDrawerToggle} sidebarWidth={SIDEBAR_WIDTH} />
      <SideBar
        mobileOpenParent={mobileOpen}
        handleLeftDrawerToggle={handleDrawerToggle}
        sidebarWidth={SIDEBAR_WIDTH}
      />
      <main className="flex flex-col w-full h-screen overflow-auto">
        <Toolbar />
        <Outlet />
      </main>
    </Box>
  );
}

export default Layout;
