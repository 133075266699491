/* eslint-disable no-nested-ternary */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  getGridSingleSelectOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Balancer from 'react-wrap-balancer';

import postSearch from '../../../api/oc_product/search/posts/postSearch';
import getSources from '../../../api/oc_product/sites/sources/gets/getSources';
import ExportButton from '../../../components/ExportButton';
import Filter from '../../../components/filter/Filter';
import Image from '../../../components/image/Image';
import { setColumnsRedux } from '../../../redux/columns/column';
import { setQueries } from '../../../redux/queries/Queries';
import splitString from '../../../utils/splitIdString';

function CustomNoRowsOverlay(params, firstMount) {
  return firstMount ? (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="body1" className="font-gothammedium text-neutral-600">
        Click
        {' '}
        <span className="font-gothambold text-neutral-950">Search</span>
        {' '}
        To Display Products
      </Typography>
    </Stack>
  ) : (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="body1" className="font-gothammedium">
        No Products Found.
      </Typography>
    </Stack>
  );
}

function CustomExportButton({
  columns,
  rows,
  totalRows,
  filteredData,
}) {
  return (
    <GridToolbarExportContainer>
      <ExportButton
        columns={columns}
        rows={rows}
        totalRows={totalRows}
        title="PLP Page Export"
        filteredData={filteredData}
      />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props, rows, columns, totalRows, filteredData) {
  return (
    <GridToolbarContainer {...props} className="flex justify-between">
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CustomExportButton
          columns={columns}
          rows={rows}
          totalRows={totalRows}
          filteredData={filteredData}
        />
      </div>
      <div className="mr-4">
        <strong>
          Returned
          {' '}
          {totalRows}
          {' '}
          products
        </strong>
      </div>
    </GridToolbarContainer>
  );
}

function CustomPagination(
  selectedPage,
  onChangeSelectedPage,
  pageSize,
  onChangePageSize,
  pageAmount,
) {
  return (
    <div className="flex justify-end gap-4">
      <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
        <Typography variant="body2" className="mr-5">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={onChangePageSize}
          autoWidth
          size="small"
          variant="standard"
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={600}>600</MenuItem>
          <MenuItem value={800}>800</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageAmount}
        page={selectedPage}
        onChange={onChangeSelectedPage}
        size="small"
      />
    </div>
  );
}

const dropshipFilterOperators = getGridSingleSelectOperators()
  .filter((operator) => operator.value === 'isAnyOf')
  .map((operator) => {
    const newOperator = { ...operator };
    const newGetApplyFilterFn = (filterItem) => (params) => {
      let isOk = true;
      filterItem?.value?.forEach((fv) => {
        if (params.value === null) {
          isOk = false;
        } else {
          isOk = isOk && params.value.some((obj) => obj.SourceName.includes(fv));
        }
      });
      return isOk;
    };
    newOperator.getApplyFilterFn = newGetApplyFilterFn;
    return newOperator;
  });

function ProductsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);
  const pageNumber = searchParams.get('page');
  const pageSize = searchParams.get('pageSize');

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pluColumns, setPluColumns] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedPage, setSelectedPage] = useState(Number(pageNumber) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(pageSize) || 100);
  const [pageAmount, setPageAmount] = useState(0);
  const [siteSources, setSiteSources] = useState([]);
  const [rowCountState, setRowCountState] = useState(totalRows);
  const [filterOpen, setFilterOpen] = useState(true);
  // const [otherFiltersOpen, setOtherFiltersOpen] = useState(false);
  const [firstLoadExample, setFirstLoadExample] = useState(true);
  const [filteredData, setFilteredData] = useState();

  const [fullQuery, setFullQuery] = useState('');

  const columnsRedux = useSelector((state) => state.columns.columnVisibilityModel) || {};

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const dropshipValueOptions = rows.flatMap((row) => (row.dropship || []).map((item) => item.SourceName))
    .filter((value, index, self) => self.indexOf(value) === index);

  const initialColumns = [
    {
      field: 'NewTab',
      headerName: 'New Tab',
      width: 80,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          className="ml-2 h-[22px] text-[8px]"
          onClick={(event) => {
            event.stopPropagation();
            window.open(`/product-info/products/${params.row.id}`, '_blank');
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
    {
      field: 'ProductImage',
      headerName: 'Image',
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Image
            src={params.value}
            height="45px"
            width="45px"
            alt="File not found"
          />
        </Box>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: 'Skus',
      headerName: 'SKUs',
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => (
        <span>
          {value.map((str) => (<p>{str}</p>))}
        </span>
      ),
    },
    {
      field: 'Eans',
      headerName: 'EANs',
      minWidth: 125,
      flex: 1,
      renderCell: ({ value }) => (
        <span>
          {value.map((str) => (<p>{str}</p>))}
        </span>
      ),
    },
    {
      field: 'ProductId',
      headerName: 'ID',
      minWidth: 100,
      flex: 1,
    },
    ...pluColumns,
    {
      field: 'Name',
      headerName: 'Product Name',
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Balancer><span style={{ overflowWrap: 'break-word' }}>{value}</span></Balancer>
      ),
    },
    {
      field: 'BrandName',
      headerName: 'Brand',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'IsActive',
      headerName: 'Active',
      type: 'boolean',
      flex: 1,
      minWidth: 60,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsApproved',
      headerName: 'Approved',
      type: 'boolean',
      flex: 1,
      minWidth: 82,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsOnSite',
      headerName: 'On-Site',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'HasCategories',
      headerName: 'Categorised',
      type: 'boolean',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsReleaseDateProduct',
      headerName: 'Pre Release Product',
      type: 'boolean',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'Hidden',
      headerName: 'Hidden',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsRestricted',
      headerName: 'Restricted',
      type: 'boolean',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'DropShipements',
      headerName: 'Dropship',
      description: 'What dropshippable sources are enabled on.',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: dropshipValueOptions,
      renderCell: (params) => (
        <div className="flex flex-col items-center justify-center w-full gap-2">
          {params.value?.length > 0 ? params.value.map((obj) => (obj.IsDropShippable ? (
            <Chip
              icon={<CheckIcon />}
              color="success"
              label={obj.SourceName}
              size="small"
              variant="outlined"
              className="w-full"
            />
          ) : (
            <Chip
              icon={<CloseIcon />}
              color="error"
              label={obj.SourceName}
              size="small"
              variant="outlined"
              className="w-full"
            />
          ))) : 'N/A'}
        </div>
      ),
      filterable: true, // Enable filtering for this column
      filterOperators: dropshipFilterOperators,
    },
    {
      field: 'UpdatedDate',
      headerName: 'Last Updated',
      minWidth: 102,
      flex: 1,
      renderHeader: () => (
        <Tooltip
          title={(
            <span>
              dd-MM-yyyy
              <br />
              hh:mm:ss
            </span>
          )}
          arrow
          placement="top"
        >
          <p className="font-gothammedium">Last Updated</p>
        </Tooltip>
      ),
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'ReleasedDate',
      headerName: 'Released Date',
      minWidth: 108,
      flex: 1,
      renderHeader: () => (
        <Tooltip
          title={(
            <span>
              dd-MM-yyyy
              <br />
              hh:mm:ss
            </span>
          )}
          arrow
          placement="top"
        >
          <p className="font-gothammedium">Released Date</p>
        </Tooltip>
      ),
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Stack direction="column">
              Unreleased
            </Stack>
          );
        }
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'NewInDate',
      headerName: 'New In Date',
      minWidth: 108,
      flex: 1,
      renderHeader: () => (
        <Tooltip
          title={(
            <span>
              dd-MM-yyyy
              <br />
              hh:mm:ss
            </span>
          )}
          arrow
          placement="top"
        >
          <p className="font-gothammedium">New In Date</p>
        </Tooltip>
      ),
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Stack direction="column">
              Not available
            </Stack>
          );
        }
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'TotalStock',
      headerName: 'Total Stock',
      type: 'number',
      flex: 1,
      minWidth: 89,
    },
    {
      field: 'LastIndexed',
      headerName: 'Last Indexed',
      minWidth: 102,
      flex: 1,
      renderHeader: () => (
        <Tooltip
          title={(
            <span>
              dd-MM-yyyy
              <br />
              hh:mm:ss
            </span>
          )}
          arrow
          placement="top"
        >
          <p className="font-gothammedium">Last Indexed</p>
        </Tooltip>
      ),
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'LastIndexedBy',
      headerName: 'Indexed By',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'Completion',
      headerName: 'Completion',
      flex: 1,
      minWidth: 95,
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return `${params.value} %`;
      },
      renderCell: (params) => (
        <div className="flex items-center justify-center w-full">
          <Chip
            label={`${params.value}%`}
            size="small"
            variant="outlined"
            color={
              params.value < 50 ? 'error'
                : ((params.value >= 50 && params.value < 100)
                  ? 'warning'
                  : 'success')
            }
          />
        </div>
      ),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    setColumns([]);
    setFilterOpen(false);
    setFirstLoadExample(false);

    const siteCode = searchParams.get('siteCode');

    const idType = searchParams.get('idType');
    const ids = searchParams.get('ids');

    const selectedSourcesOnly = searchParams.get('selectedSourcesOnly');
    const selectedProductTypesOnly = searchParams.get('selectedProductTypesOnly');

    const approved = searchParams.get('approved');
    const active = searchParams.get('active');
    const onSite = searchParams.get('onSite');
    const hasProductType = searchParams.get('hasProductType');
    const categorised = searchParams.get('categorised');

    const hasImage = searchParams.get('hasImage');
    const complete = searchParams.get('complete');
    const dropshippable = searchParams.get('dropshippable');

    const hasStockLocationsOnly = searchParams.get('hasStockLocationsOnly');
    const attributeOptionIds = splitString(searchParams.get('productAttributeLookupOptionId')).map((str) => Number(str));
    const attributeValues = searchParams.get('productAttributeDefValues');
    const dataTypeName = searchParams.get('productAttributeDataTypeName');
    const dataTypeId = searchParams.get('dataTypeId');
    const blankAttributeValue = searchParams.get('selectedBlankAttributeValue');

    let modifiedAttributeValue;

    if (dataTypeName === 'date') {
      const dateVal = attributeValues ? `${attributeValues}T00:00:00.000Z` : null;
      modifiedAttributeValue = dateVal;
    } else if (dataTypeName === 'bit') {
      if (attributeValues === 'Yes') {
        modifiedAttributeValue = '1';
      } else if (attributeValues === 'No') {
        modifiedAttributeValue = '0';
      }
    } else if (dataTypeName === 'flag') {
      if (attributeValues === 'Yes') {
        modifiedAttributeValue = 'true';
      } else if (attributeValues === 'No') {
        modifiedAttributeValue = 'false';
      }
    } else {
      modifiedAttributeValue = attributeValues;
    }

    const filterData = {
      siteId,
      siteCode,
      omsId: searchParams.get('omsId'),
      productIds: idType === 'productIds' ? splitString(ids, 'number') : [],
      plus: idType === 'plus' ? splitString(ids) : [],
      eans: idType === 'eans' ? splitString(ids) : [],
      skus: idType === 'skus' ? splitString(ids) : [],
      sourceIds: splitString(searchParams.get('sources')),
      hasSelectedSourcesOnly: selectedSourcesOnly === 'true' ? true : selectedSourcesOnly === 'false' ? false : null,
      hasSelectedProductTypesOnly: selectedProductTypesOnly === 'true' ? true : selectedProductTypesOnly === 'false' ? false : null,
      categoryIds: splitString(searchParams.get('categories')),
      isApproved: approved === 'yes' ? true : approved === 'no' ? false : null,
      isActive: active === 'yes' ? true : active === 'no' ? false : null,
      isOnSite: onSite === 'yes' ? true : onSite === 'no' ? false : null,
      hasProductType: hasProductType === 'yes' ? true : hasProductType === 'no' ? false : null,
      hasCategories: categorised === 'yes' ? true : categorised === 'no' ? false : null,
      isDropshippable: dropshippable === 'yes' ? true : dropshippable === 'no' ? false : null,
      name: searchParams.get('name'),
      brandIds: splitString(searchParams.get('brands')),
      productTypeIds: splitString(searchParams.get('productTypes')),
      hasImage: hasImage === 'yes' ? true : hasImage === 'no' ? false : null,
      isComplete: complete === 'yes' ? true : complete === 'no' ? false : null,
      erpCategorySourceId: searchParams.get('erpSource'),
      erps: splitString(searchParams.get('erpCategoryIds')),
      stockSourceLocationId: searchParams.get('stockLocation'),
      hasStockInSourceLocationIds: splitString(searchParams.get('hasStockLocations')),
      hasSelectedLocationsOnly: hasStockLocationsOnly === 'on',
      createdDateFrom: searchParams.get('createdFrom') ? `${searchParams.get('createdFrom')}T00:00:00.000Z` : null,
      createDateTo: searchParams.get('createdTo') ? `${searchParams.get('createdTo')}T23:59:59.999Z` : null,
      updateDateFrom: searchParams.get('updatedFrom') ? `${searchParams.get('updatedFrom')}T00:00:00.000Z` : null,
      updatedDateTo: searchParams.get('updatedTo') ? `${searchParams.get('updatedTo')}T23:59:59.999Z` : null,
      releaseDateFrom: searchParams.get('releasedFrom') ? `${searchParams.get('releasedFrom')}T00:00:00.000Z` : null,
      releaseDateTo: searchParams.get('releasedTo') ? `${searchParams.get('releasedTo')}T23:59:59.999Z` : null,
      searchByAttributeRequest: {
        productAttributeLanguageId: Number(searchParams.get('languageId')),
        productAttributeDefId: Number(searchParams.get('productAttributeDefId')),
        productAttributeLookupOptionIds: attributeOptionIds || [],
        productAttributeDefValue: modifiedAttributeValue,
        attributeDataTypeName: dataTypeName,
        attributeDataTypeId: Number(dataTypeId),
        selectedBlankAttributeValue: blankAttributeValue === 'true' ? true : blankAttributeValue === 'false' ? false : null,
      },
    };

    setFilteredData(filterData);

    const search = await postSearch(
      selectedPage,
      rowsPerPage,
      filterData,
    )
      .catch((err) => {
        console.error('ProductSearch', err);
        setLoading(false);
      });

    const pluMapped = new Map();

    const mappedRows = search.data?.Data?.map((product) => {
      const newRow = {
        id: product.ProductId,
        view: product.ProductId,
        Skus: product.Skus,
        Eans: product.Eans,
        ProductImage: product.ProductImage,
        ProductId: product.ProductId,
        Name: product.Name,
        BrandName: product.BrandName,
        IsActive: product.IsActive,
        IsApproved: product.IsApproved,
        IsOnSite: product.IsOnSite,
        HasCategories: product.HasCategories,
        Hidden: product.Hidden,
        IsReleaseDateProduct: product.Hidden,
        IsRestricted: product.IsRestricted,
        DropShipements: product.DropShipements,
        UpdatedDate: product.UpdatedDate,
        ReleasedDate: product.ReleasedDate,
        NewInDate: product.NewInDate,
        TotalStock: product.TotalStock,
        LastIndexed: product.LastIndexed,
        LastIndexedBy: product.LastIndexedBy,
        Completion: product.Completion,
      };
      const rowToMap = new Map(Object.entries(newRow));
      product.PluSource?.forEach((pluSource) => {
        rowToMap.set(pluSource.SourceShortDescription, pluSource.RemoteSystemId);

        const foundSource = siteSources
          .find((source) => source.ShortDescription === pluSource.SourceShortDescription);
        if (foundSource) {
          pluMapped.set(foundSource.ShortDescription, foundSource.Name);
        }
      });

      const backToObject = Object.fromEntries(rowToMap);

      return backToObject;
    });

    const pluColumnsArray = [];
    pluMapped.forEach((value, key) => {
      switch (value) {
        case 'Shogun':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 70,
            flex: 1,
          });
          break;
        case value.includes('Fluent'):
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 80,
            flex: 1,
          });
          break;
        case 'AnatwineUK':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 100,
            flex: 1,
          });
          break;
        case 'PFA':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 40,
            flex: 1,
          });
          break;
        case 'ChannelAdvisor':
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 118,
            flex: 1,
          });
          break;
        default:
          pluColumnsArray.push({
            field: key,
            headerName: value,
            minWidth: 80,
            flex: 1,
          });
          break;
      }
    });

    setPluColumns(pluColumnsArray);
    if (mappedRows === undefined) {
      setRows([]);
    } else {
      setRows(mappedRows);
    }
    setTotalRows(search.data?.Pagination.TotalCount);
    setPageAmount(search.data?.Pagination.PageCount);
    setLoading(false);
  };

  useEffect(() => {
    const siteCodeFromParams = searchParams.get('siteCode');
    if (selectedSite && !siteCodeFromParams) { // Set site from redux if there is no query param
      setSearchParams((params) => {
        params.set('siteCode', selectedSite.SiteCode);
        return params;
      });
    }
  }, []);

  useEffect(() => {
    setSearchParams((params) => {
      params.set('pageSize', 15);
      return params;
    });
    const siteCodeFromParams = searchParams.get('siteCode');
    if (selectedSite && !siteCodeFromParams) { // Set site from redux if there is no query param
      setSearchParams((params) => {
        params.set('siteCode', selectedSite.SiteCode);
        return params;
      });
    }
  }, []);

  useEffect(() => {
    setSearchParams((params) => {
      params.set('page', selectedPage);
      return params;
    });
    setSearchParams((params) => {
      params.set('pageSize', rowsPerPage);
      return params;
    });
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) { // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter((str) => str !== 'page' && str !== 'siteCode' && str !== 'omsId' && str !== 'pageSize');
    const count = filtered.length;
    const hasParams = count > 0;
    if (!firstLoadExample && hasParams) {
      fetchData();
    }
  }, [selectedPage, rowsPerPage]);

  useEffect(() => {
    const keySet = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) { // This is used to get the size of params. As searchParams.size doesn't exist on old browsers.
      keySet.add(key);
    }
    const setToArr = Array.from(keySet);
    const filtered = setToArr.filter((str) => str !== 'page' && str !== 'siteCode' && str !== 'omsId' && str !== 'pageSize');
    const count = filtered.length;
    const hasParams = count > 0;
    if (hasParams && siteSources.length > 0) {
      fetchData();
    }
  }, [siteSources]);

  useEffect(() => {
    setRowCountState((prev) => (totalRows !== undefined ? totalRows : prev));
  }, [totalRows, setRowCountState]);

  useEffect(() => {
    getSources(siteId)
      .then((res) => setSiteSources(res.data))
      .catch((err) => console.error(err));
    setFirstLoadExample(true);
    setRows([]);
    setTotalRows(0);
    setPageAmount(0);
    setRowCountState(0);
    setFilterOpen(true);
  }, [siteId]);

  useEffect(() => {
    setColumns(initialColumns);
  }, [pluColumns]);

  useEffect(() => {
    let url = window.location.search;
    url = url.replace('?', '');
    setFullQuery(url);
  }, [searchParams]);

  useEffect(() => {
    dispatch(setQueries(fullQuery));
    dispatch(setColumnsRedux({ ...columnsRedux, Skus: fullQuery.includes('skus'), Eans: fullQuery.includes('eans') }));
  }, [fullQuery]);

  const onChangePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(selectedPage, newPageCount);
    // setPageSize(newItemsPerPage);
    setSearchParams((params) => {
      params.set('pageSize', newItemsPerPage);
      return params;
    });
    setRowsPerPage(newItemsPerPage);
    setSelectedPage(newPage);
  };

  const onChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      {/* Filter */}
      <section>
        <Filter
          open={filterOpen}
          siteSources={siteSources}
          onSearch={() => {
            setSelectedPage(1);
            fetchData();
          }}
        />
      </section>
      <div>
        {/* Data Table */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" className="font-gothamlight">Products</Typography>
          <Stack direction="row" gap={4}>
            <Button variant="contained" onClick={() => setFilterOpen(!filterOpen)}>
              {filterOpen ? 'Close' : 'Filter'}
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ height: `${firstLoadExample ? '200px' : 'calc(100vh - 155px)'}`, width: '100%', mt: '1rem' }}>
          <DataGrid
            loading={loading}
            rows={rows}
            onRowClick={(params) => {
              if (!params.row.IsRestricted && !params.row.Hidden) {
                navigate(`/product-info/products/${params.row.id}`);
              }
            }}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  LastIndexed: false,
                  LastIndexedBy: false,
                  Hidden: false,
                  IsReleaseDateProduct: false,
                  IsRestricted: false,
                  Skus: false,
                  Eans: false,
                },
              },
            }}
            getRowClassName={(params) => (params.row.Hidden ? 'bg-slate-100' : params.row.IsRestricted ? 'bg-red-50' : '')}
            columnVisibilityModel={columnsRedux}
            onColumnVisibilityModelChange={(newModel) => {
              dispatch(setColumnsRedux(newModel));
              const obj = Object.values(newModel).every((value) => value === false);
              const allObj = {
                skus: false,
                eans: false,
                lastIndexed: false,
                lastIndexedBy: false,
              };

              if (obj && JSON.stringify(newModel) !== JSON.stringify(allObj)) {
                setRows([]);
              } else {
                fetchData();
              }
            }}
            disableColumnMenu
            components={{
              NoRowsOverlay: (params) => CustomNoRowsOverlay(params, firstLoadExample),
              Toolbar: (params) => CustomToolbar(
                params,
                rows,
                columns.filter((column) => columnsRedux[column.field] !== false),
                rowCountState,
                filteredData,
              ),
              Pagination: () => CustomPagination(selectedPage, onChangeSelectedPage, rowsPerPage, onChangePageSize, pageAmount, totalRows),
            }}
            disableSelectionOnClick
            sx={{ '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' } }}
            // initialState={{ pinnedColumns: { right: ['view'] } }}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 100}
            density="compact"
            experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
            className="bg-white cursor-pointer"
            columnGroupingModel={[
              {
                groupId: 'PLUs',
                children: siteSources.map((obj) => ({ field: obj.ShortDescription })),
              },
            ]}
            pageSizeOptions={[100, 200, 400, 600, 800, 1000]}
            rowsPerPageOptions={[100, 200, 400, 600, 800, 1000]}
            rowCount={rowCountState}
            paginationMode="server"
          />
        </Box>
      </div>
    </div>
  );
}

export default ProductsTable;
