import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import React from 'react';

import EnableStock from './EnableStock';
import ProductData from './ProductData';
import ProductDates from './ProductDates';
import ProductIdentifiers from './ProductIdentifiers';
import ProductStatus from './ProductStatus';

function ProductInfoHeaderDesktop({
  loading,
  productDetails,
  productIdentifiers,
  productStatus,
  locations,
  // approved,
  brands,
}) {
  return (
    <Grid container spacing={0}>

      <Grid item xs={3}>
        <ProductData
          parentLoading={loading}
          productImage={productDetails?.image}
          productName={productDetails?.name}
          styleId={productDetails?.styleId}
          productBrand={productDetails?.brand}
          existingBrands={brands}
          productColor={productDetails?.colour}
          selected={productIdentifiers}
        />
      </Grid>

      <Divider className="mx-2 ml-8" orientation="vertical" flexItem />

      <Grid item xs>
        <div className="flex items-center justify-center mx-auto">
          <ProductIdentifiers
            parentLoading={loading}
            productId={productIdentifiers.productId}
            plus={productIdentifiers.plus}
          />
        </div>
      </Grid>

      <Divider className="mx-2" orientation="vertical" flexItem />

      <Grid item xs>
        <div className="flex items-center justify-center h-full mx-auto">
          <ProductStatus
            status={productStatus.status}
            isInOms={productStatus.oms}
            approved={productStatus.approved}
            parentLoading={loading}
          />
        </div>
      </Grid>

      <Divider className="mx-2" orientation="vertical" flexItem />

      <Grid item xs={1.5}>
        <div className="flex items-center justify-center">
          <ProductDates
            parentLoading={loading}
            created={productDetails?.created}
            lastUpdated={productDetails?.lastUpdated}
            releaseDate={productDetails?.releaseDate}
          />
        </div>
      </Grid>

      <Divider className="mx-2" orientation="vertical" flexItem />

      <Grid item xs>
        <div className="flex items-center justify-center">
          <EnableStock locations={locations} parentLoading={loading} />
        </div>
      </Grid>
    </Grid>
  );
}

export default ProductInfoHeaderDesktop;
