import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import postReports from '../../api/oc_product/bulk/bulk_process/sites/reports/posts/postReports';
import ProgressSidebar from '../../components/progress_sidebar/ProgressSidebar';
import { operationTypes, queueProcessStatuses, transactionTypes } from '../../redux/bulk_operations/BulkOperations';
import Approve from './approve/Approve';
import Attributes from './attributes/Attributes';
import Campaign from './campaign/Campaign';
import Categories from './categories/Categories';
import Collection from './collection/Collection';
import EnableStock from './enable_stock/EnableStock';
import MultiSelectAttributes from './multiselect_attributes/MultiSelectAttributes';
import NewInDates from './newindates/NewInDates';
import PreReleaseProducts from './pre_release_products/PreReleaseProducts';
import PrimaryCategory from './primary_category/PrimaryCategory';
import ProductName from './product_name/ProductName';
import ProductType from './product_type/ProductType';
import ReleaseDates from './release_dates/ReleaseDates';
import SingleAttributes from './single_attributes/SingleAttributes';
import VAT from './vat/VAT';

function DisplayOperation({ page, refreshReports, inProgressData }) {
  switch (page) {
    case 'approve':
      return <Approve refreshReports={refreshReports} inProgressData={inProgressData} />;
    case 'product-name':
      return <ProductName refreshReports={refreshReports} />;
    case 'attributes':
      return <Attributes refreshReports={refreshReports} />;
    case 'campaign':
      return <Campaign refreshReports={refreshReports} />;
    case 'categories':
      return <Categories refreshReports={refreshReports} />;
    case 'collection':
      return <Collection refreshReports={refreshReports} />;
    case 'enable-stock':
      return <EnableStock refreshReports={refreshReports} inProgressData={inProgressData} />;
    case 'multi-select':
      return <MultiSelectAttributes refreshReports={refreshReports} />;
    case 'product-type':
      return <ProductType refreshReports={refreshReports} />;
    case 'primary-category':
      return <PrimaryCategory refreshReports={refreshReports} />;
    case 'release-dates':
      return <ReleaseDates refreshReports={refreshReports} />;
    case 'single-select':
      return <SingleAttributes refreshReports={refreshReports} />;
    case 'vat':
      return <VAT refreshReports={refreshReports} />;
    case 'new-in-dates':
      return <NewInDates refreshReports={refreshReports} />;
    case 'pre-release-products':
      return <PreReleaseProducts refreshReports={refreshReports} />;
    default:
      break;
  }
}

function BulkContainer() {
  const reduxDispatch = useDispatch();

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const siteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);

  const options = [
    {
      label: 'Approve',
      value: 'approve',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Attributes',
      value: 'attributes',
    },
    {
      label: 'Campaign',
      value: 'campaign',
    },
    {
      label: 'Categories',
      value: 'categories',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Collection',
      value: 'collection',
    },
    {
      label: 'Enable Stock',
      value: 'enable-stock',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Multi-Select Attributes',
      value: 'multi-select',
    },
    {
      label: 'Primary Category',
      value: 'primary-category',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Product Name',
      value: 'product-name',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Product Type',
      value: 'product-type',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Single-Select Attributes',
      value: 'single-select',
    },
    {
      label: 'VAT',
      value: 'vat',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Dates',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Release Dates',
      value: 'release-dates',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'New In Dates (Table Bulk)',
      value: 'new-in-dates',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
    {
      label: 'Pre-Release Products',
      value: 'pre-release-products',
      hidden: siteCode === 'OC' || siteCode === 'JQ',
    },
  ].filter((obj) => !obj.hidden);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');

  const defaultPage = siteCode === 'OC' || siteCode === 'JQ' ? 'attributes' : options[0].value;

  const [selectedPage, setSelectedPage] = React.useState(page || defaultPage);

  const [inProgressData, setInProgressData] = React.useState([]);

  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);

  const handleRefreshReports = () => {
    postReports(siteId, null, null, '', 0, 0, [2, 3]) // "Active": 2, "Processing": 3,
      .then((res) => setInProgressData(res.data.reverse()))
      .catch((err) => console.error(err));
  };

  const updateInProgressData = (newData, currentPage) => {
    if (currentPage === 'approve' || currentPage === 'enable-stock') {
      setInProgressData(newData);
    }
  };

  React.useEffect(() => {
    reduxDispatch(transactionTypes());
    reduxDispatch(operationTypes());
    reduxDispatch(queueProcessStatuses());
  }, []);

  React.useEffect(() => {
    if (siteCode === 'OC' || siteCode === 'JQ') {
      setSelectedPage(options[0].value);
      setSearchParams((params) => {
        params.set('page', options[0].value);
        return params;
      });
    }
  }, [siteCode]);

  return (
    <div className="flex justify-center h-full gap-8 pt-2 m-8 overflow-hidden mt-4">
      <section className="flex flex-col h-[calc(100vh-120px)] w-full overflow-auto pt-2">
        <div className="w-full">
          <Box sx={{ minWidth: 220, width: 356 }} className="flex items-baseline justify-center">
            <FormControl fullWidth size="small" id="bulk-container-form-control">
              <InputLabel id="bulk-container-input-label">Page</InputLabel>
              <Select
                labelId="bulk-container-select-label"
                id="bulk-container-select"
                value={page || selectedPage}
                label="Page"
                onChange={(event) => {
                  setSelectedPage(event.target.value);
                  setSearchParams((params) => {
                    params.set('page', event.target.value);
                    return params;
                  });
                }}
              >
                {options.map((obj) => (!obj.hidden && obj.label === 'Dates'
                  ? (
                    <li className="pl-4 pr-4 text-xl">
                      <strong>{obj.label}</strong>
                      <hr className="w-full border-black" />
                    </li>
                  ) : (
                    <MenuItem key={obj.value} value={obj.value}>{obj.label}</MenuItem>
                  )))}
              </Select>
            </FormControl>
            <p className="text-[#47b3c2] w-48 pl-2 text-lg">
              {(selectedPage === 'approve' || selectedPage === 'categories' || selectedPage === 'primary-category' || selectedPage === 'enable-stock'
                || selectedPage === 'product-type' || selectedPage === 'release-dates' || selectedPage === 'new-in-dates' || selectedPage === 'vat'
                || selectedPage === 'product-name' || selectedPage === 'pre-release-products')
                || selectedSite.IsLead === false ? 'Site ' : 'Group '}
              Level
            </p>
          </Box>
        </div>
        <div className="flex justify-start h-full mt-4">
          <DisplayOperation page={selectedPage} refreshReports={() => handleRefreshReports()} inProgressData={inProgressData} />
        </div>
      </section>
      <ProgressSidebar page={selectedPage} inProgress={inProgressData} updateInProgressData={updateInProgressData} endpoint={postReports} />
    </div>
  );
}

export default BulkContainer;
