import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { CircularProgress, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import patchReleaseDate from '../../../../../api/oc_product/product/releaseDate/patchReleaseDate';
import parseDateString from '../../../../../utils/parseDateString';

function ProductInfo({
  parentLoading,
  created,
  lastUpdated,
  releaseDate,
}) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const [loading, setLoading] = useState(true);
  const [releaseDateLoading, setReleaseDateLoading] = useState(false);
  const [hasReleaseDateUpdated, setHasReleaseDateUpdated] = useState(false);
  const [productReleaseDate, setProductReleaseDate] = useState('Unreleased');
  const [productReleaseIsoDate, setProductReleaseIsoDate] = useState();
  const [selectedDateTimeForReleaseDate, setSelectedDateTimeForReleaseDate] = useState(new Date());
  const [updateRequestPending, setUpdateRequestPending] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  useEffect(() => {
    if (releaseDate) {
      setProductReleaseDate(parseDateString(releaseDate));
      setProductReleaseIsoDate(releaseDate);
      setSelectedDateTimeForReleaseDate(new Date(releaseDate));
    }
  }, [releaseDate]);

  const update = () => {
    setReleaseDateLoading(true);
    setUpdateRequestPending(true);
    patchReleaseDate(id, siteId, selectedDateTimeForReleaseDate)
      .then(() => setUpdateRequestPending(false))
      .then(() => {
        if (!selectedDateTimeForReleaseDate) {
          toast.success('Release date updated to Null', { autoClose: 2000 });
          setProductReleaseDate('Unreleased');
        } else {
          toast.success('Release date updated', { autoClose: 2000 });
          setProductReleaseDate(parseDateString(selectedDateTimeForReleaseDate.toISOString()));
          setProductReleaseIsoDate(selectedDateTimeForReleaseDate.toISOString());
        }
      })
      .catch((error) => {
        console.error(error);
        setUpdateRequestPending(false);
      })
      .finally(() => {
        setHasReleaseDateUpdated(!hasReleaseDateUpdated);
        setUpdateRequestPending(false);
        setReleaseDateLoading(false);
      });
  };

  const cancel = () => {
    setHasReleaseDateUpdated(!hasReleaseDateUpdated);
    setSelectedDateTimeForReleaseDate(productReleaseIsoDate ? new Date(productReleaseIsoDate) : new Date());
  };

  return (
    <div>
      <Typography variant="body1" gutterBottom className="font-gothammedium">
        Product Dates
      </Typography>
      <Stack spacing={1} className="mt-3">
        <Stack>
          <Typography className="font-gothammedium" variant="body2">Created:</Typography>
          {loading ? (
            <Skeleton width="12rem" />
          ) : (
            <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
              {parseDateString(created)}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography className="font-gothammedium" variant="body2">Updated:</Typography>
          {loading ? (
            <Skeleton width="12rem" />
          ) : (
            <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
              {parseDateString(lastUpdated)}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography className="font-gothammedium" variant="body2">Release Date:</Typography>
          {loading ? (
            <Skeleton width="12rem" />
          ) : (
            <div>
              {(hasReleaseDateUpdated || updateRequestPending) ? (
                <>
                  <DateTimePicker
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={selectedDateTimeForReleaseDate}
                    onChange={(newValue) => { setSelectedDateTimeForReleaseDate(newValue); }}
                    renderInput={(params) => <TextField {...params} />}
                    disablePast
                    ampm={false}
                  />
                  <div className="flex flex-row">
                    <IconButton disabled={!!(DateTimePicker.error)}>
                      {!releaseDateLoading ? <CheckIcon color="success" onClick={update} className="text-success-600" />
                        : <CircularProgress size={20} thickness={5} />}
                    </IconButton>
                    <IconButton>
                      <CloseIcon
                        className="text-error-600"
                        onClick={cancel}
                      />
                    </IconButton>
                  </div>
                </>
              ) : (
                <span className="flex flex-row items-center">
                  <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
                    {productReleaseDate}
                  </Typography>
                  <IconButton>
                    <CreateIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setHasReleaseDateUpdated(!hasReleaseDateUpdated)}
                    />
                  </IconButton>
                </span>
              )}
            </div>
          )}
        </Stack>

      </Stack>
    </div>
  );
}

export default ProductInfo;

ProductInfo.defaultProps = {
  created: '',
  lastUpdated: '',
  parentLoading: false,
  releaseDate: '',
};

ProductInfo.propTypes = {
  created: PropTypes.string,
  lastUpdated: PropTypes.string,
  parentLoading: PropTypes.bool,
  releaseDate: PropTypes.string,
};
