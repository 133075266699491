import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';

import SiteSelect from './site_select/SiteSelect';

function MobileBurgerIcon({ handleLeftDrawerToggle }) {
  const theme = useTheme();

  const drawerToggle = () => handleLeftDrawerToggle();

  return (
    <Box
      sx={{
        display: { md: 'none', sm: 'flex', xs: 'flex' },
        width: 228,
        [theme.breakpoints.down('md')]: {
          width: 'auto',
        },
      }}
    >
      <Box component="span" sx={{ display: { md: 'none', sm: 'block', xs: 'block' }, flexGrow: 1 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={drawerToggle}
          sx={{ display: { md: 'none' }, mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function Header({ handleLeftDrawerToggle, sidebarWidth }) {
  return (
    <AppBar
      position="fixed"
      sx={{
        ml: { md: `${sidebarWidth}px` },
        width: { md: `calc(100% - ${sidebarWidth}px)` },
        zIndex: 3,
      }}
      color="inherit"
      className="border-b border-gray-200 shadow-none"
    >
      <Toolbar>
        {/* Mobile Burger Icon */}
        <MobileBurgerIcon handleLeftDrawerToggle={handleLeftDrawerToggle} />
        {/* Site Select */}
        <SiteSelect variant="standard" />
        {/* Wizard Instructions */}
        <div className="flex justify-end w-full">
          {window.location.pathname === '/dropship/wizards/product' && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              href="https://jdsports-client-resources.co.uk/jdsports-client-resources/front-end-dev/projects/prism/wizard/docs/NikeProductWizardWorkflow__08-22.pdf"
              download="ProductWizardStepByStepGuide.pdf"
              target="_blank"
              sx={{
                border: '1px solid lightgrey',
                backgroundColor: 'white',
                color: '#333',
                '&:hover': {
                  color: '#217aba',
                  backgroundColor: 'white',
                },
              }}
            >
              Step-By-Step Guide
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
