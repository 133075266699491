import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';

import Drawer from './drawers/Drawer';
import SecondaryDrawer from './drawers/secondary/SecondaryDrawer';

function SideBar({ mobileOpenParent, handleLeftDrawerToggle, sidebarWidth }) {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState('');
  const [parentSelected, setParentSelected] = useState('');
  const [open, setOpen] = useState(false);

  const [selectedTabName, setSelectedTabName] = useState('Dashboard');

  const toggleDrawer = (openDraw) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(openDraw);
  };

  const handleTab = (panel, name) => {
    setParentSelected(name);
    setSelectedTab(panel);
  };

  const handleDrawerToggle = () => {
    handleLeftDrawerToggle();
  };

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleItemClick = (obj) => {
    if (!matches) handleLeftDrawerToggle();
    if (obj.link === '#') {
      setOpen(true);
    } else {
      setOpen(false);
      setSelectedTabName(obj.name);
    }

    handleTab(obj.id, obj.name);
    if (open === true && obj.name === parentSelected) setOpen(false);
  };

  const handleSubItemClick = (obj) => {
    setSelectedTabName(obj.name);
    if (open === true) setOpen(false);
  };

  React.useEffect(() => {
    const currentURL = window.location.href;

    const isProdURL = currentURL.includes('prod.prism.jdplc.com');
    const isUatURL = currentURL.includes('dev.prism.jdplc.com');

    if (isProdURL) {
      document.title = `PRISM - ${selectedTabName}`;
    } else if (isUatURL) {
      document.title = `PRISM Dev - ${selectedTabName}`;
    } else {
      document.title = `PRISM Staging - ${selectedTabName}`;
    }

    return () => {
      document.title = 'PRISM';
    };
  }, [selectedTabName]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: { md: sidebarWidth }, overflow: 'hidden' }}
      aria-label="sidebar pages"
    >
      <Drawer
        sidebarWidth={sidebarWidth}
        handleItemClick={(obj, index) => handleItemClick(obj, index)}
        openDrawer={mobileOpenParent}
        closeDrawer={handleDrawerToggle}
      />

      <SecondaryDrawer
        selectedTab={selectedTab}
        sidebarWidth={sidebarWidth}
        parentSelected={parentSelected}
        toggleDrawer={toggleDrawer}
        handleSubItemClick={handleSubItemClick}
        open={open}
      />
    </Box>
  );
}

export default SideBar;
