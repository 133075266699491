import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import getPermissions from '../../../api/oc_product/sites/permissions/gets/getPermissions';
// import getUserById from '../../../api/private/users_management/gets/getUserById';
import { setSelectedSitePermissions } from '../../../redux/sites/sitePermissions';
import { setSelectedSite } from '../../../redux/user/User';

function combineSites(data) {
  const sites = [];
  if (Array.isArray(data)) {
    data.forEach((obj) => {
      obj.Sites.forEach((site) => {
        sites.push({
          ...site,
          parent: obj.Name,
          SiteGroupId: obj.SiteGroupId,
        });
      });
    });
    return sites;
  }
  return [];
}

function SiteSelect({ variant }) {
  const dispatch = useDispatch();

  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);
  // const userId = useSelector((state) => state.user?.userProfile?.id);
  const user = useSelector((state) => state.user.userProfile);
  const fascias = useSelector((state) => state.fascias);
  const usersSites = useSelector((state) => state.user?.userProfile.sid);
  const userToken = useSelector((state) => state.user?.loginResponse.JwtToken);
  const decodedToken = jwtDecode(userToken);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = React.useState({});
  const [combinedSites, setCombinedSites] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [siteId, setSiteId] = React.useState();

  const setSiteFromQuery = async (combined) => {
    const usersSids = JSON.parse(decodedToken.sid).sort((a, b) => a - b);
    const siteCodeFromUrl = searchParams.get('siteCode');
    const foundSite = combined.find((site) => site.SiteCode === siteCodeFromUrl);
    if (foundSite && usersSids?.includes(foundSite.SiteId)) {
      dispatch(setSelectedSite(foundSite));
      setSelected(foundSite);
      setSiteId(foundSite.SiteId);
    } else if (selectedSite && !siteCodeFromUrl) { // Set site from redux if there is no query param
      setSearchParams((params) => {
        params.set('siteCode', selectedSite.SiteCode);
        return params;
      });
      setSelected(selectedSite);
      setSiteId(selectedSite.SiteId);
    } else {
      const defaultJD = combined.find((site) => site.SiteId === usersSids[0]);
      if (defaultJD) {
        dispatch(setSelectedSite(defaultJD));
        setSearchParams((params) => {
          params.set('siteCode', defaultJD.SiteCode);
          return params;
        });
        setSelected(defaultJD);
        setSiteId(defaultJD.SiteId);
      }
    }
  };

  React.useEffect(() => {
    if (siteId) {
      getPermissions(siteId)
        .then((res) => dispatch(setSelectedSitePermissions(res.data)))
        .catch((err) => console.error(err));
    }
  }, [siteId]);

  React.useEffect(() => {
    if (fascias.length > 0) {
      const combined = combineSites(fascias);
      setCombinedSites(combined);
      setSiteFromQuery(combined);
    }
  }, [fascias]);

  React.useEffect(() => {
    const cs = [...combinedSites];
    const usArr = JSON.parse(usersSites);
    const filtered = cs.filter((obj) => usArr.includes((obj.SiteId)));
    setOptions(filtered);
  }, [combinedSites]);

  const handleOptionSelected = (event, value) => {
    setSelected(value);
    dispatch(setSelectedSite(value));
    setSearchParams((params) => {
      params.set('siteCode', value.SiteCode);
      return params;
    });
    getPermissions(value.SiteId)
      .then((res) => dispatch(setSelectedSitePermissions(res.data)))
      .catch((err) => console.error(err));
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E9F2F8',
    fontWeight: 'bold',
    padding: '5px 10px',
    position: 'sticky',
    top: '-8px',
    zIndex: '1',
  };

  const renderGroup = (params) => [
    <div key={params.key} style={groupStyles}>
      {params.group}
    </div>,
    params.children,
  ];

  // Check if the URL contains a product ID so we can disable Autocomplete if it does
  const isProductPage = /\/product-info\/products\/\d+/.test(window.location.href);
  const hideSettings = window.location.href.includes('settings') || window.location.href.includes('image-checker') || user?.user_right === 'ImageChecker';
  return (
    <div>
      {!hideSettings && (
        <Autocomplete
          value={selected}
          options={options}
          groupBy={(option) => option.parent}
          getOptionLabel={(option) => option.Name}
          onChange={handleOptionSelected}
          renderInput={(params) => (
            <TextField {...params} label="Select Site" variant={variant} />
          )}
          size="small"
          filterOptions={createFilterOptions({
            limit: 100,
          })}
          renderGroup={renderGroup}
          className="w-[300px]"
          disableClearable
          disabled={isProductPage}
        />
      )}
    </div>
  );
}

export default SiteSelect;
