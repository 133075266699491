/* eslint-disable no-unused-vars */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

import Image from '../../../../../components/image/Image';
import Modal from '../../../../../components/Modal';
import EnableStock from './EnableStock';
import ProductData from './ProductData';
import ProductDates from './ProductDates';
import ProductIdentifiers from './ProductIdentifiers';
import ProductStatus from './ProductStatus';

function Slide({ children, activeSlide }) {
  return (
    <div
      className="min-w-full transition-all"
      style={{ transform: `translate(-${100 * activeSlide}%)` }}
    >
      {children}
    </div>
  );
}

function ProductInfoHeaderMobile({
  loading,
  productDetails,
  productIdentifiers,
  productStatus,
  locations,
  approved,
  brands,
}) {
  // const [openModal, setOpenModal] = React.useState(false);

  const MAX_SLIDES = 2;
  const [activeSlide, setActiveSlide] = React.useState(0);
  const handleNext = () => {
    if (activeSlide < MAX_SLIDES) {
      const newSlide = activeSlide + 1;
      setActiveSlide(newSlide);
    }
  };
  const handleBack = () => {
    if (activeSlide > 0) {
      const newSlide = activeSlide - 1;
      setActiveSlide(newSlide);
    }
  };

  return (
    <div>
      <div className="flex justify-between w-full mb-4">
        {activeSlide > 0 && (
          <Button variant="contained" size="small" onClick={handleBack}>Previous</Button>
        )}
        {activeSlide < MAX_SLIDES && (
          <div className="ml-auto">
            <Button variant="contained" size="small" onClick={handleNext}>Next</Button>
          </div>
        )}
      </div>

      <div className="relative flex h-full overflow-hidden transition-all">
        <Slide activeSlide={activeSlide}>
          <div className="flex items-center justify-center">
            <Stack direction="row" spacing={2}>
              {loading ? (
                <Skeleton variant="rectangular" width="14rem">
                  <div style={{ paddingTop: '100%' }} />
                </Skeleton>
              ) : (
                <div className="flex items-center justify-center w-48">
                  <Image
                    src={productDetails?.image}
                    alt="product"
                  />
                </div>
              )}
              <div>
                <ProductData
                  parentLoading={loading}
                  productImage={productDetails?.image}
                  productName={productDetails?.name}
                  styleId={productDetails?.styleId}
                  productBrand={productDetails?.brand}
                  existingBrands={brands}
                  productColor={productDetails?.colour}
                  selected={productIdentifiers}
                />
              </div>
            </Stack>
          </div>
        </Slide>

        <Slide activeSlide={activeSlide}>
          <div className="flex items-center justify-evenly">
            <div>
              <ProductIdentifiers
                parentLoading={loading}
                productId={productIdentifiers.productId}
                plus={productIdentifiers.plus}
              />
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <ProductStatus
                status={productStatus.status}
                isInOms={productStatus.oms}
                onSite={productStatus.onSite}
                isInFredhopper={productStatus.fredhopper}
                approved={productStatus.approved}
                parentLoading={loading}
              />
            </div>
          </div>
        </Slide>

        <Slide activeSlide={activeSlide}>
          <div className="flex items-start justify-evenly">
            <div>
              <ProductDates
                parentLoading={loading}
                created={productDetails?.created}
                lastUpdated={productDetails?.lastUpdated}
                releaseDate={productDetails?.releaseDate}
              />
            </div>

            <Divider orientation="vertical" flexItem />

            <div>
              <EnableStock locations={locations} parentLoading={loading} />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
}

export default ProductInfoHeaderMobile;
